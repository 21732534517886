const environment = {
	api: {
		url: "https://api-dev.ampmemberships.com",
	},
	localhost: {
		tenantKey: "",
	},
	aws: {
		url: "https://dev-ampmemberships-com.s3.us-west-1.amazonaws.com/public",
	},
	sentry: {
		dsn: "https://1198be3e7a1543f6aed4aabcfd70bce7@o1295353.ingest.sentry.io/6521245",
	},
	google: {
		maps_api: "AIzaSyBWo_SGwBfdpeD6l8AsYz_iQPyB3qmu7t8",
	}
};

export default environment;